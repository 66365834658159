<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px" transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <v-btn flat class="mb-3 btn-second" v-bind="props" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-combobox         
                  v-if="user_type != userTypeConstant.CLIENT"         
                  v-model="item.client"
                  name="client"
                  item-title="name"
                  item-value="id"
                  :items="clients"
                  label="Cliente*"
                  autocomplete="off"
                  :disabled="disabled"
                  :rules="[rules.requiredComboBox]"
                  clearable
                ></v-combobox>

                <v-text-field label="Nombre*" name="name" v-model="item.name" :disabled="disabled" :rules="[rules.required]"></v-text-field>

                <v-text-field label="Email" v-model="item.mail" :disabled="disabled" :rules="item.mail ? [rules.email] : []"></v-text-field>

                <v-text-field label="Telefono" v-model="item.phone" :disabled="disabled" :rules="item.phone ? [rules.integer] : []"></v-text-field>

                <v-text-field label="Dirección*" v-model="item.address" :disabled="disabled" :rules="[rules.required]"></v-text-field>

                <v-text-field label="Localidad*" v-model="item.location" :disabled="disabled" :rules="[rules.required]"></v-text-field>

                <v-text-field label="Código Postal*" v-model="item.postal_code" :disabled="disabled" :rules="[rules.required]"></v-text-field>

                <v-combobox
                  v-model="item.provincia"
                  name="provincia"
                  item-title="name"
                  item-value="id"
                  :items="provincias"
                  label="Provincia*"
                  autocomplete="off"
                  :disabled="disabled"
                  :rules="[rules.requiredComboBox]"
                  clearable
                ></v-combobox>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading" v-if="!disabled">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { clientService } from "@/libs/ws/clientService";
import { generalService } from "@/libs/ws/generalService";
import Constant from "@/constants/sections";
import ValidMixin from "@/mixins/ValidMixin.vue";
import UserTypeConstant from "@/constants/user_type";
export default {
  mixins: [ValidMixin],
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      clients: [],
      loading: false,
      name: "",
      item: {
        id: "",
        name: "",
        mail: "",
        phone: "",
        address: "",
        client: null,
      },
      defaultItem: null,
      provincias: [],
      user_type: null,
      userTypeConstant: UserTypeConstant,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.loadClients();
    this.loadProvincias();
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    loadProvincias() {
      generalService
        .filter({}, Constant.SECTION_PROVINCIAS)
        .then((response) => {
          this.provincias = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadClients() {
      clientService
        .filter()
        .then((response) => {
          this.clients = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;

        const data = {
          id: this.item.id,
          name: this.item.name,
          mail: this.item.mail,
          phone: this.item.phone,
          address: this.item.address,
          provincia: this.item.provincia,
          location: this.item.location,
          postal_code: this.item.postal_code,
          client: this.item.client,
        };

        if (this.item.id > 0) {
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se modifico correctamente.");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se creo correctamente");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
