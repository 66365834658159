<template>
  <div>
    <Title :pages="[{ icon: 'user-tie', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
     
      <Modal
        v-if="create_access"
        :button_name="'Crear ' + modal_title"
        :modal_title="modal_title"
        :page_title="page_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :editItem="mainItem"
      />

      <v-card class="mb-4">
        <div class="row mb-2 ps-3 pe-3 mt-0 mb-2">
          <div class="col-md-12">
            <div class="col-md-12" style="background: white">
              <p class="text-h6 mt-2 mb-0"><font-awesome-icon icon="filter" class="pr-2" />Filtros</p>
              <v-divider class="mt-0"></v-divider>
              <div class="row pt-2">
                <div class="col-md-3">
                    <v-combobox
                      v-model="filters.almacen"
                      item-title="label"
                      item-value="id"
                      :items="almacenes"
                      label="Almacen"
                      autocomplete="off"
                      :disabled="disabled"
                      clearable
                      :loading="loading_almacenes"
                    ></v-combobox>
                </div>
                <div class="col-md-3">
                    <v-combobox
                      v-model="filters.dock"
                      item-title="label"
                      item-value="id"
                      :items="muelles"
                      label="Muelle"
                      autocomplete="off"
                      :disabled="disabled"
                      clearable
                      :loading="loading_muelles"
                    ></v-combobox>
                </div>

                <div class="col-md-3">
                    <v-combobox
                      v-model="filters.categoria_zona"
                      item-title="name"
                      item-value="id"
                      :items="categorias"
                      label="Categorias"
                      autocomplete="off"
                      :disabled="disabled"
                      clearable
                      :loading="loading_categorias"
                    ></v-combobox>
                </div>


                <div class="col-md-3 mt-2" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter" prepend-icon="mdi-magnify">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

 
      <v-card v-if="list_access">
        <v-card-title>
          <v-row align="center" class="mt-1">
            <v-col>
              <span>{{ page_title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" single-line hide-details label="Buscar" density="compact"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando...">
          <template v-slot:item="{ item }">
            <tr>              
              <td>{{ item.name }}</td>
              <td>{{ item.categoria_zona != null ? item.categoria_zona.name : "-" }}</td>
              <td>{{ item.almacen != null ? item.almacen.label : "-" }}</td>
              <td>{{ item.dock != null ? item.dock.label : "-" }}</td>
              <td class="text-center">{{ item.ubicaciones.length }}</td>
              <td class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="update_access" v-bind="props" small color="blue" class="mr-2" v-on:click="editItem(item)" size="small"> mdi-pencil</v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="blue" class="mr-2" v-on:click="viewItem(item)" size="small"> mdi-eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="delete_access" v-bind="props" small color="red" class="mr-2" v-on:click="deleteItem(item)" size="small">mdi-delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />
    </v-container>
  </div>
</template>

<script>
import { zonaService } from "@/libs/ws/zonaService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Modal from "./Modal.vue";
import { almacenService } from "@/libs/ws/almacenService";
import { categoriaZonaService } from "@/libs/ws/categoriaZonaService";
import { muelleService } from "@/libs/ws/muelleService";

export default {
  components: { Modal },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: zonaService,
      page_title: "Zonas",
      modal_title: "Zona",
      loading_almacenes: false,
      loading_muelles: false,
      loading_categorias: false,
      filters: {
        zona: "",
        categoria_zona: "",
        dock: "",
        almacen: "",
      },
      headers: [
        {
          title: "Nombre",
          align: "left",
          sortable: true,
          key: "name",
        },
        {
          title: "Categoria",
          align: "left",
          sortable: true,
          key: "categoria_zona.name",
        },
        {
          title: "Almacen",
          align: "left",
          sortable: true,
          key: "almacen.name",
        },
        {
          title: "Muelle",
          align: "left",
          sortable: true,
          key: "dock.name",
        },
        {
          title: "Cantidad de ubicaciones",
          align: "center",
          sortable: true,
          key: "ubicaciones",
        },
        { title: "Accion", align: "center", sortable: false, key: "" },
      ],
    };
  },
  mounted() {
    this.loadList(this.service);
    this.loadAlmacenes();
    this.loadCategorias();
    this.loadMuelles();
  },
  methods: {
    loadAlmacenes() {
      this.loading_almacenes = true;
      almacenService
        .filter()
        .then((response) => {
          this.almacenes = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        })
        .finally(() => {
          this.loading_almacenes = false;
        });
    },
    loadCategorias() {
      this.loading_categorias = true;
      categoriaZonaService
        .filter()
        .then((response) => {
          this.categorias = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        })
        .finally(() => {
          this.loading_categorias = false;
        });
    },
    loadMuelles() {
      this.loading_muelles = true;
      muelleService
        .filter()
        .then((response) => {
          this.muelles = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        })
        .finally(() => {
          this.loading_muelles = false;
        });
    },
    getFilterParams() {
      return this.filters;
    },
    filter() {
      this.loadList(this.service);
    },
  },
};
</script>
