<template>
  <div>
    <v-layout class="pull-left">
      <v-dialog :modelValue="show" max-width="1200px" @keydown.esc="$emit('close')" @click:outside="$emit('close')">
        <v-card>
          <v-card-title class="headline">
            <span>Historial</span>
          </v-card-title>

          <v-card-text>
            <v-data-table class="mt-4" :headers="headers" :items="history" :loading="loading_history" loading-text="Cargando...">
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.date_time }}</td>
                  <td>{{ item.user.username }}</td>
                  <td>{{ item.origin_ubicacion != null ? item.origin_ubicacion.name : "-" }}</td>
                  <td>{{ item.destination_ubicacion != null ? item.destination_ubicacion.name : "-" }}</td>
                  <td>{{ item.origin_lpn != null ? item.origin_lpn.code : "-" }}</td>
                  <td>{{ item.destination_lpn != null ? item.destination_lpn.code : "-" }}</td>
                  <td>{{ item.origin_lpn_status	!= null ? item.origin_lpn_status.name : "-" }}</td>
                  <td>{{ item.destination_lpn_status != null ? item.destination_lpn_status.name : "-" }}</td>
                  <td>{{ item.asn != null ? "#" + item.asn.id : "-" }}</td>
                  <td>{{ item.shipment_order != null ? "#" + item.shipment_order.id : "-" }}</td>                  
                  <td>{{ item.task != null ? "#" + item.task.id + "("+item.task.task_type.name+")" : "-" }}</td>
                  <td>-</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('close')">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import { lpnService } from "@/libs/ws/lpnService";
export default {
  props: {
    show: Boolean,
    close: Function,
    code: String,
  },
  name: "ModalHistory",
  mixins: [],
  created() {},
  data() {
    return {
      loading_history: false,
      history: [],
      headers: [
        {
          title: "Fecha",
          align: "left",
          sortable: true,
          key: "code",
        },
        {
          title: "Usuario",
          align: "left",
          sortable: false,
          key: "flow_type.name",
        },
        {
          title: "Ubicacion Origen",
          align: "left",
          sortable: false,
          key: "state.name",
        },
        {
          title: "Ubicacion Destino",
          align: "left",
          sortable: false,
          key: "state.name",
        },
        {
          title: "LPN Origen",
          align: "center",
          sortable: false,
          key: "",
        },
        {
          title: "LPN Destino",
          align: "center",
          sortable: false,
          key: "",
        },
        {
          title: "Estado LPN Org.",
          align: "center",
          sortable: false,
          key: "",
        },
        {
          title: "Estado LPN Des.",
          align: "center",
          sortable: false,
          key: "",
        },
        {
          title: "ASN",
          align: "center",
          sortable: false,
          key: "",
        },
        {
          title: "Orden de envio",
          align: "center",
          sortable: false,
          key: "",
        },
        {
          title: "Tarea",
          align: "center",
          sortable: false,
          key: "",
        }
      ],
    };
  },
  mounted() {},
  computed: {},
  watch: {
    code() {
      this.getHistory();
    },
  },
  methods: {
    getHistory() {
      this.loading_history = true;

      lpnService
        .getHistory({ lpn_code: this.code })
        .then((response) => {
          this.history = response.result.list;
          this.loading_history = false;
        })
        .catch((error) => {
          this.toast.error(error);
          this.loading_history = false;
        });
    },
  },
};
</script>
