<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="closeModal()" max-width="700px"
      transition="dialog-bottom-transition">
      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="addItem">
          <v-card-title>
            <span class="headline">Recibir ASN</span>
          </v-card-title>

          <v-card-text>
            <div v-if="asn != null && asn.zona == null && item.zona == null">
              <p>Seleccione la zona del almacen donde va a realizar la recepcion</p>
              <v-combobox v-model="item.zona" name="zonas" item-title="label" item-value="id" :items="zonas"
                label="Zona*" autocomplete="off" :disabled="disabled" :rules="[rules.requiredComboBox]"
                clearable></v-combobox>
            </div>

            <div v-else>
              <v-combobox v-model="item.presentation_atributes.product" name="producto" item-title="label"
                item-value="id" :items="products" label="Producto*" autocomplete="off" :disabled="disabled"
                :rules="[rules.requiredComboBox]" @update:modelValue="loadAmount" clearable></v-combobox>

              <p class="fs-16">Recepción</p>
              <v-radio-group v-model="item.accepted">
                <v-radio style="margin-top: 5px" label="Aceptada" :value="true"></v-radio>
                <v-radio style="margin-top: 5px" label="Rechazada" :value="false"></v-radio>
              </v-radio-group>

              <div v-if="item.accepted === false">
                <v-combobox v-model="item.motivo" name="motivo" item-title="name" item-value="id" :items="motivos"
                  label="Motivo*" autocomplete="off" :rules="[rules.requiredComboBox]" clearable></v-combobox>
              </div>

              <div v-if="item.accepted === true">
                <v-combobox v-model="item.presentation_atributes.product_status" name="estado" item-title="name"
                  item-value="id" :items="states" label="Estado" autocomplete="off" :disabled="disabled"
                  :rules="[rules.requiredComboBox]" clearable></v-combobox>

                <v-combobox v-model="item.lpn" name="lpn" item-title="code" item-value="id" :items="lpns"
                  label="Seleccione el LPN asignado*" autocomplete="off" :disabled="disabled"
                  :rules="[rules.requiredComboBox]" clearable></v-combobox>
              </div>

              <v-text-field v-if="item.accepted != null" autocomplete="off" name="amount"
                :label="item.accepted === true ? 'Cantidad recibida' : 'Cantidad rechazada'" type="number"
                v-model="item.amount" :loading="loading_amount" :disabled="loading_amount"
                :rules="[rules.required, rules.integer]" />

              <div v-if="item.accepted === true">
                <v-text-field
                  v-if="item.presentation_atributes.product != null && item.presentation_atributes.product.requiere_fecha_expiracion"
                  v-model="item.presentation_atributes.expiration_date" name="expiration_date"
                  label="Fecha de Expiración" scrollable :rules="[rules.required]" type="date"></v-text-field>

                <v-row v-if="item.presentation_atributes.product != null">
                  <v-col cols="4" v-if="item.presentation_atributes.product.requiere_fecha_expiracion">
                    <v-text-field v-model="item.presentation_atributes.manufacture_date" name="manufacture_date"
                      label="Fecha de Fabricación" scrollable type="date"></v-text-field>
                  </v-col>
                  <v-col cols="4" v-if="item.presentation_atributes.product.tiene_control_por_lotes">
                    <v-text-field autocomplete="off" name="lote" label="Lote" type="text"
                      v-model="item.presentation_atributes.lote" />
                  </v-col>
                  <v-col cols="12" v-if="item.presentation_atributes.product.tiene_control_por_serie">
                    <v-btn color="blue-darken-2" dark class="mb-4" @click="addSerie()"
                      :disabled="series_arr.length === parseInt(item.amount)" size="small">
                      <v-icon left>mdi-plus</v-icon> AGREGAR SERIE
                    </v-btn>

                    <v-data-table :headers="header_serie" :items="series_arr" class="elevation-1"
                      no-data-text="No hay datos disponibles" hide-default-footer>
                      <template v-slot:item="{ index }">
                        <tr>
                          <td>
                            <v-text-field v-model="series_arr[index].serie" placeholder="Ingrese serie"
                              variant="underlined" :error-messages="series_arr[index].errorMessage" class="custom-text-field-table"
                              @change="validateSerie(index)"></v-text-field>
                          </td>

                          <td class="text-center" style="width: 110px">
                            <v-icon v-on:click="deleteSerie(index)" color="red" class="mr-2" small>mdi-delete</v-icon>
                          </td>
                        </tr>
                      </template>

                    </v-data-table>
                  </v-col>
                </v-row>



                <!--<v-textarea v-model="item.observations" name="observation" label="Observación" scrollable></v-textarea>-->
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeModal()">Cerrar</v-btn>
            <v-btn color="success" :loading="loading" text type="submit">Recibir</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import { zonaService } from "@/libs/ws/zonaService";
import { productoService } from "@/libs/ws/productoService";
import { lpnService } from "@/libs/ws/lpnService";
import { asnService } from "@/libs/ws/asnService";
export default {
  components: {},
  mixins: [ValidMixin],
  props: {
    dialog: Boolean,
    updateDialog: Function,
    asn: Object,
  },
  data() {
    return {
      item: {
        zona: null,
        accepted: null,
        amount: 0,
        observations: null,
        lpn: null,
        motivo: null,
        presentation_atributes: {
          product: null,
          expiration_date: null,
          manufacture_date: null,
          lote: null,
          product_status: null,
          serie: null,
          series_arr: null
        },
      },
      itemDefault: null,
      loading: false,
      loading_amount: false,
      zonas: [],
      products: [],
      presentations: [],
      lpns: [],
      states: [],
      motivos: [],
      pallet_base_units: 0,
      header_serie: [
        { title: "Serie", key: "product.serie", sortable: false },
        { title: "Accion", sortable: false },

      ],
      series_arr: []
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.item = JSON.parse(JSON.stringify(this.itemDefault));
        this.loadZonas();
        this.loadProducts();
        this.loadLpns();
        this.loadStatesProducts();
        this.loadMotivos();
      }
    },
  },
  mounted() {
    this.itemDefault = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    validateSerie(index) {
      const serieIngresada = this.series_arr[index].serie.trim();

      if (!serieIngresada) {
        this.series_arr[index].errorMessage = "La serie no puede estar vacía.";
        return;
      }

      const existe = this.series_arr.some((item, i) => i !== index && item.serie === serieIngresada);

      if (existe) {
        this.series_arr[index].errorMessage = "La serie ya ha sido escaneada.";
        this.series_arr[index].serie = ""; 
      } else {
        this.series_arr[index].errorMessage = "";
      }
    },


    addSerie() {
      this.series_arr.push({ serie: "", errorMessage: "" }); // Agrega un input vacío con el campo de error
    },
    deleteSerie(index) {
      this.series_arr = this.series_arr.filter((_, i) => i !== index);
    },
    closeModal() {
      this.item = JSON.parse(JSON.stringify(this.itemDefault));
      this.$emit('updateDialog', false)
    },
    loadMotivos() {
      asnService.getMotivos().then((response) => {
        this.motivos = response.result.list;
      });
    },
    loadStatesProducts() {
      productoService.getStates().then((response) => {
        this.states = response.result.list;
      });
    },
    loadZonas() {
      zonaService.filter({ almacen: this.asn.almacen, allow_recepcion: '1' }).then((response) => {
        this.zonas = response.result.list;
      });
    },
    loadProducts() {
      productoService.filter({ client: this.asn.client.id }).then((response) => {
        this.products = response.result.list;
      });
    },
    loadAmount() {
      this.loading_amount = true;
      //Obtener la catidad de 1 paller para Producto - composite_presentation_amount_for
      if (this.item.presentation_atributes.product != null) {
        productoService.compositePresentationAmountFor(null, this.item.presentation_atributes.product.id, productoService.PRESENTATION_TYPE_PALLET).then((response) => {
          this.item.amount = response.result;
          this.pallet_base_units = response.result;
        })
          .finally(() => {
            this.loading_amount = false;
          });
      }
    },
    loadLpns() {
      lpnService.getInboundNotUsed().then((response) => {
        this.lpns = response.result.list;
      });
    },
    validateReception() {
      if (
        this.item.amount > this.pallet_base_units
      ) {
        this.toast.error("No se puede recibir más de " + this.pallet_base_units + " unidades en un pallet.");
        return false;
      }
      return true;
    },
    addItem() {
      if (this.validateReception()) {
        if (this.loading) return;

        this.loading = true;

        var data = JSON.parse(JSON.stringify(this.item));
        const seriesValidas = this.series_arr
          .filter(item => item.errorMessage === "")
          .map(item => ({ serie: item.serie }));

        data["series_arr"] = seriesValidas.length > 0 ? seriesValidas : null;
        data["asn"] = { id: this.asn.id };

        /*
        for (const [key, value] of Object.entries(this.item.presentation_atributes)) {
          data[key] = value;
        }

        delete data["presentation_atributes"];
        */

        asnService
          .receiveItem(data)
          .then((response) => {
            if (response.status) {
              this.toast.success("Recepcion realizada con exito");
              this.closeModal();
              this.$emit("loadList");
            } else {
              if (Object.prototype.hasOwnProperty.call(response, "message")) {
                this.toast.error(response.message);
              } else {
                this.toast.error(response.msg);
              }
            }
          })
          .catch((e) => {
            this.toast.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
