<template>
  <div>
    <Title :pages="[{ icon: 'arrow-right', page: page_title.toUpperCase() }]"></Title>
    <v-container fluid>

      <v-btn flat class="mb-3 btn-second" @click="updateDialog(true)">Crear {{ modal_title }}</v-btn>
      <v-btn outlined class="ml-3 mb-3 btn-third" @click="updateDialogImportExcel(true)">Importar {{ modal_title}}</v-btn>
     
      <Modal v-if="create_access" :button_name="'Crear ' + modal_title" :modal_title="modal_title"
        :page_title="page_title" :formBtn="formBtn" :dialog="dialog" :service="service" @updateDialog="updateDialog"
        @loadList="loadList(service)" :editItem="mainItem" />
    
      <ModalImportExcel v-if="create_access" :button_name="'Importar ' + modal_title" :modal_title="modal_title"
        :page_title="page_title" formBtn="Importar" :dialog="dialog_import_excel" :service="service"
        @updateDialog="updateDialogImportExcel" @loadList="loadList(service)" />

      <!-- 
        desde
        hasta
        tipo de documento
        estado
        cliente 
        referencia externa 
        -->
      
      <v-card class="mb-4">
        <div class="row mb-2 ps-3 pe-3 mt-0 mb-2">
          <div class="col-md-12">
            <div class="col-md-12" style="background: white">
              <p class="text-h6 mt-2 mb-0"><font-awesome-icon icon="filter" class="pr-2" />Filtros</p>
              <v-divider class="mt-0"></v-divider>
              <div class="row pt-2">
                <div class="col-md-3">
                  <v-text-field v-model="filters.date_from" density="comfortable" type="date" name="date_from"
                    label="Desde" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.date_to" density="comfortable" type="date" name="date_to" label="hasta"
                    :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-combobox v-model="filters.document_type" name="document_type" density="comfortable"
                    item-title="name" item-value="id" :items="documents_types" label="Tipo de Documento"
                    autocomplete="off" :disabled="disabled" clearable></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.external_reference" density="comfortable" label="Referencia Externa"
                    :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.code" density="comfortable" label="Numero"
                    :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-combobox v-model="filters.status" density="comfortable" name="status" item-title="name"
                    item-value="id" :items="status" label="Estado" autocomplete="off" :disabled="disabled"
                    clearable></v-combobox>
                </div>
                <div class="col-md-3" v-if="user_type != userTypeConstant.CLIENT">
                  <v-combobox v-model="filters.client" density="comfortable" name="cliente" item-title="name"
                    item-value="id" :items="clients" label="Cliente" autocomplete="off" :disabled="disabled"
                    clearable></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-combobox v-model="filters.almacen" density="comfortable" name="almacen" item-title="name"
                    item-value="id" :items="almacenes" label="Almacen" autocomplete="off" :disabled="disabled"
                    clearable></v-combobox>
                </div>

                <div :class="user_type == userTypeConstant.CLIENT ? 'col-md-3 pt-2' : 'col-md-12 pt-0 pb-3'"
                  style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter" prepend-icon="mdi-magnify">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card>
        <v-card-title>
          <v-row align="center" class="mt-1">
            <v-col>
              <span>{{ page_title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" single-line hide-details label="Buscar"
                density="compact"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- v-if="list_access" -->
        <div class="mb-4 mt-2" v-show="update_access">
          <v-menu offset-y>
            <template v-slot:activator="{ props }">
              <v-icon :color="selected.length === 0 ? 'grey' : 'purple'"
                :class="selected.length === 0 ? 'ml-4 cursor-default' : 'ml-4 cursor-pointer'" v-bind="props"> mdi-plus
              </v-icon>
            </template>

            <v-list>
              <v-list-item @click="createPreparationWave">
                <v-list-item-title>Crear ola de preparación</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openAssignmentModal">
                <v-list-item-title>Asignar a ola de preparación</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openAssignmentModalMuelle">
                <v-list-item-title>Asignar a muelle</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-tooltip>
            <template v-slot:activator="{ props }">
              <v-icon v-if="update_access" v-bind="props" small color="green" class="mr-2"
                v-on:click="createPickingDetail()" size="small">mdi-package </v-icon>
            </template>
            <span>Reservar stock</span>
          </v-tooltip>
          <v-tooltip>
            <template v-slot:activator="{ props }">
              <v-icon v-if="update_access" v-bind="props" small color="red" class="mr-2" v-on:click="clancelItem()"
                size="small">mdi-close </v-icon>
            </template>
            <span>Cancelar reservas</span>
          </v-tooltip>
        </div>
        <v-data-table v-if="list_access" :headers="headers" :items="list" :search="search" :loading="loading"
          v-model="selected" show-select loading-text="Cargando..." @update:select-all="toggleSelectAll">
          <template v-slot:item="{ item, isSelected, toggleSelect }">
            <tr>
              <td class="pl-2">
                <v-checkbox :disabled="item.wave != null" style="margin: 0px; padding: 0px" hide-details
                  :model-value="isSelected({ value: item.id })" @update:modelValue="toggleSelect({ value: item.id })" />
              </td>
              <td>{{ item.code }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.estimated_departure_date }}</td>
              <td v-if="user_type != userTypeConstant.CLIENT">{{ item.wave != null ? item.wave.id : "" }}</td>
              <td v-if="user_type != userTypeConstant.CLIENT">{{ item.dock != null ? item.dock.name : "" }}</td>
              <td>{{ item.document_type.name }}</td>
              <td v-if="user_type != userTypeConstant.CLIENT">{{ item.client.user.username }}</td>
              <td>{{ item.address + ", " + item.location }}</td>
              <td>{{ item.external_reference }}</td>
              <td>{{ item.status.name }}</td>
              <td>{{ item.sub_status.name }}</td>

              <td class="text-center" style="min-width: 150px">
                <v-tooltip bottom v-if="user_type != userTypeConstant.CLIENT">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="violet" class="mr-2" v-on:click="createPickingDetail(item)"
                      size="small"> mdi-package </v-icon>
                  </template>
                  <span>Preparar envio</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="update_access" v-bind="props" small color="blue" class="mr-2"
                      v-on:click="editItem(item)" size="small"> mdi-pencil</v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="blue" class="mr-2" v-on:click="viewItem(item)" size="small">
                      mdi-eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.status.id == service.STATUS_STOCK_RESERVADO">
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="delete_access" v-bind="props" small color="red" class="mr-2"
                      v-on:click="clancelItem(item)" size="small">mdi-close </v-icon>
                  </template>
                  <span>Cancelar Reserva</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.status.id == service.STATUS_PENDIENTE_PREPARACION">
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="delete_access" v-bind="props" small color="red" class="mr-2"
                      v-on:click="deleteItem(item)" size="small">mdi-delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <ModalAssignPreparation :dialog="assignment_modal" :updateDialog="updateDialogAssignWave"
        @assignPreparation="assignToPreparationWave" :loading="loading_assign" />

      <DialogLoading :dialog="loading_create_wave" text="Creando la ola de preparación." />

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
        @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

      <ModalAssignMuelle title="Asignar muelle" button_accept="Asignar" :dialog="dialog_assign_muelle"
        @updateDialog="updateDialogMuelle" @assignMuelle="assignMuelle" :loading="loading_assign_muelle" />

      <ModalAssignMuelle 
        :loading="loading_picking_detail" :dialog="dialog_picking_detail" title="Preparar envío"
        button_accept="Confirmar" @updateDialog="
          dialog_picking_detail = false;
          selected_shipment_order = null;
        " 
        @assignMuelle="confirmPickDetail" />

      <DialogYesNo :loading="loading_cancel" :open="dialog_cancel" title="Cancelar Reservas"
        message="¿Estas seguro que desea cancelar las reservas de los envios?" @cancel="
          dialog_cancel = false;
        selected_shipment_order = null;
        " @accept="cancelItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import { shipmentOrderService } from "@/libs/ws/shipmentOrderService";
import { almacenService } from "@/libs/ws/almacenService";
import { clientService } from "@/libs/ws/clientService";
import { documentsTypeService } from "@/libs/ws/documentsTypeService";
import UserTypeConstant from "@/constants/user_type";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Modal from "./Modal.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogYesNo from "@/components/DialogYesNo.vue";
import ModalAssignPreparation from "./ModalAssignPreparation.vue";
import DialogLoading from "@/components/DialogLoading.vue";
import ModalAssignMuelle from "./ModalAssignMuelle.vue";
import ModalImportExcel from "./ModalImportExcel.vue";
export default {
  components: { Modal, DialogDelete, DialogYesNo, DialogLoading, ModalAssignPreparation, ModalAssignMuelle, ModalImportExcel, },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      service: shipmentOrderService,
      page_title: "Ordenes de envio",
      modal_title: "Orden de envio",
      documents_types: [],
      clients: [],
      almacenes: [],
      status: [],
      userTypeConstant: UserTypeConstant,
      user_type: null,
      headers: [
        {
          title: "Numero",
          align: "left",
          sortable: true,
          key: "code",
        },

        {
          title: "Fecha",
          align: "left",
          sortable: true,
          key: "date",
        },
        {
          title: "Fecha estimada de entrega",
          align: "left",
          sortable: true,
          key: "estimated_departure_date",
        },
        {
          title: "Ola",
          align: "left",
          sortable: true,
          key: "wave.id",
          visible_for_client: false,
        },
        {
          title: "Muelle",
          align: "left",
          sortable: true,
          key: "dock.id",
          visible_for_client: false,
        },
        {
          title: "Tipo de Documento",
          align: "left",
          sortable: true,
          key: "document_type.name",
        },
        {
          title: "Cliente",
          align: "left",
          sortable: true,
          key: "client.user.username",
          visible_for_client: false,
        },
        {
          title: "Destino",
          align: "left",
          sortable: true,
          key: "receiver",
        },
        {
          title: "Ref. Externa",
          align: "left",
          sortable: true,
          key: "external_reference",
        },
        {
          title: "Estado",
          align: "left",
          sortable: true,
          key: "status",
        },
        {
          title: "Sub estado",
          align: "left",
          sortable: true,
          key: "sub_status",
        },
        { title: "Accion", align: "center", sortable: false, key: "" },
      ],
      filters: {
        date_from: new Date().toISOString().substr(0, 10),
        date_to: new Date().toISOString().substr(0, 10),
        estimated_arrival_date: "",
        document_type: null,
        status: null,
        client: null,
        almacen: null,
        code: "",
        external_reference: "",
      },
      dialog_picking_detail: false,
      loading_picking_detail: false,
      selected_shipment_order: null,
      selected: [],
      assignment_modal: false,
      selectedPreparation: null,
      loading_create_wave: false,
      dialog_cancel: false,
      loading_cancel: false,
      dialog_assign_muelle: false,
      loading_assign_muelle: false,
      dialog_import_excel: false
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.filters.almacen = JSON.parse(localStorage.getItem("almacen"));

    //recorro los headers y saco el cliente si el usuario es el cliente
    this.headers = this.headers.filter((header) => {
      if (header.visible_for_client || header.visible_for_client == undefined) {
        return true;
      }
      return this.user_type != UserTypeConstant.CLIENT;
    });

    this.loadList(this.service);
    this.loadAlmacenes();
    this.loadClientes();
    this.loadDocumentTypes();
    this.loadStatus();
  },
  methods: {
    updateDialogImportExcel(value) {
      if (!value) {
        this.close();
      }
      this.dialog_import_excel = value;
    },
    updateDialogAssignWave() {
      this.assignment_modal = false;
    },
    updateDialogMuelle() {
      this.dialog_assign_muelle = false;
    },
    toggleSelectAll(isSelected) {
      if (isSelected) {
        const selectableItems = this.list.filter((item) => item.wave === null);
        this.selected = selectableItems.map((item) => item.id);
      } else {
        // Desmarcar todo
        this.selected = [];
      }
    },
    assignMuelle(item) {
      this.loading_assign_muelle = true;
      const param = {
        shipment_orders_ids: this.selected,
        dock: { id: item.id },
      };

      this.service
        .assign_dock(param)
        .then((response) => {
          if (response.status) {
            this.toast.success("El muelle se asigno correctamente.");
            this.loadList(this.service);
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((e) => {
          this.toast.error(e);
        })
        .finally(() => {
          this.loading_assign_muelle = false;
          this.dialog_assign_muelle = false; // Cierra el modal
        });
    },
    openAssignmentModalMuelle() {
      this.dialog_assign_muelle = true;
    },
    createPreparationWave() {
      this.loading_create_wave = true;
      const param = {
        shipment_orders: this.selected,
      };

      this.service
        .create_wave(param)
        .then((response) => {
          if (response.status) {
            this.toast.success("La ola de preparacion se creo correctamente.");
            this.loadList(this.service);
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((e) => {
          this.toast.error(e);
        })
        .finally(() => {
          this.loading_create_wave = false;
        });
    },
    openAssignmentModal() {
      this.assignment_modal = true;
    },
    assignToPreparationWave(item) {
      this.loading_assign = true;
      const param = {
        shipment_orders: this.selected,
        wave: item.id,
      };

      this.service
        .assign_wave(param)
        .then((response) => {
          if (response.status) {
            this.toast.success("La ola de preparacion se asigno correctamente.");
            this.loadList(this.service);
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((e) => {
          this.toast.error(e);
        })
        .finally(() => {
          this.loading_assign = false;
          this.assignment_modal = false; // Cierra el modal
        });
    },
    createPickingDetail(item) {
      //this.selected_shipment_order = item;
      if (item != undefined && item != null) {
        this.selected = [item.id];
      }
      this.dialog_picking_detail = true;
    },
    confirmPickDetail(item) {
      if (this.loading_picking_detail) return;

      if (this.selected_shipment_order != null && isNaN(this.selected_shipment_order.id)) {
        this.selected.push(this.selected_shipment_order.id);
      }

      const data = {
        //shipment_order_id: this.selected_shipment_order.id,
        shipment_order_ids: this.selected,
        dock: { id: item.id }
      };

      if (this.selected.length == 0) {
        this.toast.error("No se ha seleccionado ninguna orden de envio");
        return;
      }

      this.loading_picking_detail = true;
      const currentObject = this;
      this.service
        .generatePickingDetail(data)
        .then((response) => {
          console.log(response);
          if (response.status === true) {
            this.toast.success(response.msg, { dangerouslyHTMLString: true });
            this.dialog_picking_detail = false;
            this.filter();
          } else {
            this.toast.error(response.msg, { dangerouslyHTMLString: true });
          }
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        })
        .finally(() => {
          this.loading_picking_detail = false;
          this.selected_shipment_order = null;
        });
    },
    getFilterParams() {
      return this.filters;
    },
    filter() {
      this.loadList(this.service);
    },
    loadAlmacenes() {
      almacenService
        .filter({})
        .then((response) => {
          this.almacenes = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadClientes() {
      clientService
        .filter({})
        .then((response) => {
          this.clients = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadDocumentTypes() {
      documentsTypeService
        .filter({ order_type: "OUT" })
        .then((response) => {
          this.documents_types = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadStatus() {
      shipmentOrderService
        .list_status()
        .then((response) => {
          this.status = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    clancelItem(item) {
      //this.selected_shipment_order = item;
      if (item != undefined && item != null) {
        this.selected = [item.id];
      }
      this.dialog_cancel = true;
    },
    cancelItemConfirm() {
      if (this.loading_cancel) return;

      if (this.selected_shipment_order != null && isNaN(this.selected_shipment_order.id)) {
        this.selected.push(this.selected_shipment_order.id);
      }

      if (this.selected.length == 0) {
        this.toast.error("No se ha seleccionado ninguna orden de envio");
        return;
      }

      const data = {
        //shipment_order_id: this.selected_shipment_order.id,
        shipment_order_ids: this.selected,
      };
      this.loading_cancel = true;
      this.service
        .cancel_reserve(data)
        .then((response) => {
          if (response.status) {
            this.toast.success("La orden de envio se cancelo correctamente.");
            this.dialog_cancel = false;
            this.selected = [];
            this.filter();
          } else {
            this.toast.error(response.msg);
          }
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        })
        .finally(() => {
          this.loading_cancel = false;
        });
    },
    createPickingSelected() {
      this.cancelItemConfirm();
    },
    cancelSelected() {
      this.cancelItemConfirm();
    },
  },
};
</script>
